import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/pagination"
import Search from "../components/SearchContainer"
import tottori_bird from "../svg/tottori_bird.svg"
import button_group from "../images/Group-2.png"

const GroupPage = ({ data, pageContext }) => (
  <Layout>
    <Seo
      title="団体をみる"
    />

    <section className="p-16">
      <div className="container max-w-5xl mx-auto">
        <div className="w-full flex justify-around">
          <div className="flex">
            <div className="hidden md:block px-4">
              <img src={button_group} width="120" />
            </div>
            <h1 className="py-4 text-2xl md:text-4xl text-base-dark font-bold leading-none">団体をみる</h1>
            <div className="hidden md:block px-4">
              {/* <StaticImage src="../svg/tottori_bird.svg" alt="" width={48} /> */}
              <img src={tottori_bird} width="48" className="py-4" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-base-main">
      <div className="container max-w-5xl mx-auto">

        <div className="flex flex-wrap px-4">
          <div className="">
            <Link to={`/`}>
              <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
            </Link>
            <span className="mx-4 text-sm">></span>
            <Link to={`/group/1`}>
              <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">団体をみる</button>
            </Link>
          </div>
        </div>

        {/* <div className="flex flex-wrap px-4">
          <div className="w-full py-6">
            <div className="relative border-2 border-base-dark bg-white rounded-lg p-4">
              <div className="font-bold">
                フリーワード
              </div>
              <div className="py-4">
                <input type="text" className="bg-secondry-white border-2 border-gray-200 w-full p-2 rounded-md" />
              </div>

              <div className="py-4 font-bold">
                ジャンルで探す
              </div>
              <div>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">農業</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">教育</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">環境・災害</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">まちづくり</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">福祉</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">建築</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">国際交流</span>
                </label>
                <label class="inline-flex items-center pr-4">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" /><span class="ml-2 text-gray-700">サードプレイス</span>
                </label>
              </div>
              <div className="py-4 font-bold">
                キャンパスで探す
              </div>
              <select name="example" className="bg-secondry-white border-2 border-gray-200 p-2 rounded-md">
                <option value="サンプル1">選択してください</option>
                <option value="サンプル1">鳥取キャンパス</option>
                <option value="サンプル2">米子キャンパス</option>
                <option value="サンプル3">その他</option>
              </select>

              <div className="w-full flex items-center justify-center py-4">
                <button className="border-2 border-primary-main bg-primary-main text-base-dark hover:opacity-60 rounded-full font-bold text-center px-16 py-3 flex">
                  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_876_2802)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6668 14.6668H15.6134L15.2401 14.3068C16.8401 12.4401 17.6668 9.89344 17.2134 7.18677C16.5868 3.4801 13.4934 0.520102 9.7601 0.0667684C4.1201 -0.626565 -0.626565 4.1201 0.0667684 9.7601C0.520102 13.4934 3.4801 16.5868 7.18677 17.2134C9.89344 17.6668 12.4401 16.8401 14.3068 15.2401L14.6668 15.6134V16.6668L20.3334 22.3334C20.8801 22.8801 21.7734 22.8801 22.3201 22.3334C22.8668 21.7868 22.8668 20.8934 22.3201 20.3468L16.6668 14.6668ZM8.66677 14.6668C5.34677 14.6668 2.66677 11.9868 2.66677 8.66677C2.66677 5.34677 5.34677 2.66677 8.66677 2.66677C11.9868 2.66677 14.6668 5.34677 14.6668 8.66677C14.6668 11.9868 11.9868 14.6668 8.66677 14.6668Z" fill="#171F3A" />
                    </g>
                    <defs>
                      <clipPath id="clip0_876_2802">
                        <rect width="22.6667" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="px-2">検索</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end p-4">
          <select name="example" className="border-2 border-base-dark rounded-md py-2 px-4">
            <option value="サンプル1">更新が新しい順</option>
            <option value="サンプル1">更新が古い順</option>
          </select>
        </div> */}
      </div>
    </section>

    <section className="">
      <div className="container max-w-5xl mx-auto">
        <div className="flex flex-wrap justify-between">

          <div className="flex flex-wrap">
            {data.allMicrocmsPortalGroup.edges.map(({ node }) => (
              <div className="w-full md:w-1/2 lg:w-1/3 p-4" key={node.portalGroupId}>
                <article className="overflow-hidden rounded-xl shadow-xl bg-white">
                  <div className="w-full">
                    <Link to={`/group-detail/${node.portalGroupId}`}>
                      <img src={node.accountThumbnail.url} className="w-full hover:opacity-60" />
                    </Link>
                  </div>
                  <div className="flex px-3 md:px-4 py-4 hover:opacity-60">
                    <Link to={`/group-detail/${node.portalGroupId}`} className="font-bold text-md text-base-dark h-8">
                      {node.accountName}
                    </Link>
                  </div>
                  {(() => {
                    const MAX_LENGTH = 30
                    let stringAbstract = node.abstract
                    if (stringAbstract.length > MAX_LENGTH) {
                      stringAbstract = stringAbstract.substr(0, MAX_LENGTH) + '...'
                    }
                    return (
                      <div className="p-3 md:px-4 text-base-dark">
                        {stringAbstract}
                      </div>
                    )
                  })()}
                  <div className="py-4 text-md text-base-dark">
                    {/* <Link to={`/news/`} className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1 hover:bg-blue-600">
                      <span className="text-xs">{node.category}</span>
                    </Link> */}
                    <span className="bg-base-dark rounded-full font-bold text-md text-white mx-2 px-4 py-1">
                      <span className="text-xs">{node.category}</span>
                    </span>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>

      </div>
    </section>

    <section className="flex flex-col items-center px-4">
      <div className="max-w-5xl mx-auto m-8">
        <div className="flex flex-wrap">
          <div className="w-full py-6 flex">
            {(() => {
              if (!pageContext.isFirst) {
                return (
                  <Link to={`/group/${pageContext.currentPage - 1}`} rel="next" className="px-2">
                    <button className="rounded-full text-white bg-secondry-main hover:opacity-60 w-10 h-10">
                      ←
                    </button>
                  </Link>
                )
              }
            })()}
            <Pagination page="group" totalCount={data.allMicrocmsPortalGroup.totalCount} />
            {(() => {
              if (!pageContext.isLast) {
                return (
                  <Link to={`/group/2`} rel="next" className="px-2">
                    <button className="rounded-full text-white bg-secondry-main hover:opacity-60 w-10 h-10">
                      →
                    </button>
                  </Link>
                )
              }
            })()}
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default GroupPage

export const query = graphql`
  query($limit: Int!, $skip: Int!) {
    allMicrocmsPortalGroup(sort: { fields: [createdAt], order: ASC }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          accountThumbnail {
            url
          }
          abstract
          body
          category
          id
          accountName
          updatedAt
          createdAt
          portalGroupId
        }
      }
    }
  }
`
