import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Axios from "axios"
import * as JsSearch from "js-search"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

class Search extends Component {
    state = {
        newsList: [],
        search: [],
        searchResults: [],
        isLoading: true,
        isError: false,
        searchQuery: ``,
    }

    async componentDidMount() {
        // Axios.get(process.env.BASE_URL,
        Axios.get('https://lifefix.microcms.io/api/v1' + '/portal-group',
            {
                headers: { 'X-MICROCMS-API-KEY': `${process.env.API_KEY}` }
            })
            .then(result => {
                const newsData = result.data
                this.setState({ newsList: newsData.contents })
                console.log(newsData);
                this.rebuildIndex()
            })
            .catch(err => {
                this.setState({ isError: true })
                console.log(`====================================`)
                console.log(`Something bad happened while fetching the data\n${err}`)
                console.log(`====================================`)
            })
    }

    rebuildIndex = () => {
        const { newsList } = this.state

        const dataToSearch = new JsSearch.Search(`isbn`)

        /**
         *  defines a indexing strategy for the data
         * more more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
         */
        dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()

        /**
         * defines the sanitizer for the search
         * to prevent some of the words from being excluded
         *
         */
        dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()

        /**
         * defines the search index
         * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
         */
        dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(`isbn`)

        dataToSearch.addIndex(`accountName`) // sets the index attribute for the data
        dataToSearch.addIndex(`body`) // sets the index attribute for the data

        dataToSearch.addDocuments(newsList) // adds the data to be searched
        this.setState({ search: dataToSearch, isLoading: false })
    }

    /**
     * handles the input change and perform a search with js-search
     * in which the results will be added to the state
     */
    searchData = e => {
        const { search } = this.state
        const queryResult = search.search(e.target.value)
        this.setState({ searchQuery: e.target.value, searchResults: queryResult })
    }
    handleSubmit = e => {
        e.preventDefault()
    }

    render() {
        const {
            isError,
            isLoading,
            newsList,
            searchResults,
            searchQuery,
        } = this.state
        const queryResults = searchQuery === `` ? newsList : searchResults

        if (isLoading) {
            return (
                <div style={{ margin: `1.2rem 1rem 1.2rem 1rem` }}>
                    <h1 style={{ marginTop: `3em`, textAlign: `center` }}>
                        Getting the search all setup
                    </h1>
                </div>
            )
        }
        if (isError) {
            return (
                <div style={{ margin: `1.2rem 1rem 1.2rem 1rem` }}>
                    <h1 style={{ marginTop: `3em`, textAlign: `center` }}>Ohh no!!!!!</h1>
                    <h3
                        style={{
                            marginTop: `2em`,
                            padding: `2em 0em`,
                            textAlign: `center`,
                        }}
                    >
                        Something really bad happened
                    </h3>
                </div>
            )
        }

        return (
            <div>
                <div style={{ margin: `0 auto` }}>
                    <form onSubmit={this.handleSubmit}>
                        <div style={{ margin: `0 auto` }}>
                            <label htmlFor="Search" style={{ paddingRight: `10px` }}>
                                フリーワード
                            </label>
                            <input
                                id="Search"
                                value={searchQuery}
                                onChange={this.searchData}
                                placeholder="Enter your search here"
                                style={{ margin: `0 auto`, width: `400px` }}
                                className="border-2 border-black"
                            />
                        </div>
                    </form>
                    <div>
                        {queryResults.length}件HITしました
                    </div>

                    <div className="flex flex-wrap">
                        {queryResults.map(item => {
                            return (
                                <div className="w-full md:w-1/2 lg:w-1/3" key={`row_${item.isbn}`}>
                                    <article className="overflow-hidden rounded-xl shadow-xl">
                                        <img width={48} height={48} src={item.thumbnail.url} />
                                        <div className="flex px-3 md:px-4 py-4">
                                            <Link to={`/group-detail`} className="font-bold text-md underline hover:text-blue-400">
                                                {item.accountName}
                                            </Link>
                                        </div>
                                        <div className="p-3 md:px-4">
                                            {item.body}
                                        </div>
                                        <div className="p-3 md:px-4">
                                            {item.category}
                                        </div>
                                    </article>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        )
    }
}

export default Search
